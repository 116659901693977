<template>
  <div class="container">
    <div class="noticeBox">
      <div class="title">【公告】{{ releasedData.auctionTitle }}——拍卖公告</div>

      <div class="content">
        <div class="time">
          <span>项目编号：{{ releasedData.applicationNumber }}</span>
          <span class="line">|</span>
          <span
            >发布时间：{{
              releasedData.releaseTime
                ? releasedData.releaseTime.split(" ")[0]
                : "---"
            }}</span
          >
        </div>

        <div class="contentDetail">
          <div class="tit">
            我司（<span>{{ releasedData.applyForBusiness }}</span>
            ，公告后续文中均成为“我司”）定于<span>{{
              releasedData.auctionStartTime
            }}</span>
            在电池之家拍卖平台（网址：http://dianchipaimai.com）进行公开拍卖活动，公开进行电池资产拍卖处置，诚邀广大客户企业前来参拍竞价。我司将以公开、公平、公正的原则开展本次拍卖活动，烦请各位客户企业代表认真阅读本拍卖公告。
          </div>
          <div class="tit">
            本次拍卖标的：
            <div v-html="releasedData.introduction" class="bdConte"></div>
          </div>
          <div class="tit">
            本次竞价活动为：
            <span v-if="releasedData.isPreQuotation == 'Y'"
              >2轮报价，第一轮为预报价，参拍企业须在报名时同步完成预报价的填写；第二轮为正式竞价。预报价为客户企业代表在报名时同步填写的企业意向价格。预报价仅可填写一次，且报名后不可修改，请竞价代表谨慎填写。正式竞价为匿名公开报价，所有人均可查看报价价格。拍卖采用增价方式进行报价。</span
            >
            <span v-else
              >1轮报价，即在拍卖开始时进入正式竞价。正式竞价为匿名公开报价，所有人均可查看报价价格。拍卖采用增价方式进行报价，拍卖结束报价最高者判定为最终成交客户。</span
            >
          </div>
          <div class="tit">
            本场拍卖
            <span v-if="releasedData.isReservePrice == 'Y'"
              >设置保留价，最终出价若高于保留价即可成交，若低于保留价，则本场拍卖流拍。</span
            >
            <span v-else>不设置保留价，最终出价最高者即可成交。</span>
          </div>
          <div class="tit">
            本次报价按照
            <span v-if="releasedData.quoting == '1'"
              >单价进行报价，按照单“{{
                releasedData.quotationUnit
              }}”报价。成交数量以最终交易/拉运数量为准。</span
            >
            <span v-else>整体进行报价，成交数量以公告展示商品数量为准。</span>
          </div>
          <div class="tit">
            竞价结束后，已成交竞买人须向平台支付交易服务费，交易服务费收取标准为：
            <span>服务费费率{{ releasedData.auctionServiceFeeRate }}%</span
            >，平台向竞买人提供税额为6%增值税专用服务费发票。
          </div>

          <div class="flex3Box">
            <div class="centerItem">
              <div class="item_tit" v-if="releasedData.isPreview == 'Y'">
                报名/勘验/预报价时间:
              </div>
              <div class="item_tit" v-else>报名/预报价时间:</div>
              <div class="item_label">报名/预报价时间：</div>
              <div class="item_value">
                即时开始 -- {{ releasedData.auctionStartTime }}结束
              </div>
              <div class="item_label">勘验时间：</div>
              <div class="item_value">
                <span v-if="releasedData.isPreview == 'Y'"
                  >{{
                    releasedData.previewStartTime.substring(
                      0,
                      releasedData.previewStartTime.indexOf("日") + 1
                    )
                  }}
                  --
                  {{
                    releasedData.previewEndTime.substring(
                      0,
                      releasedData.previewEndTime.indexOf("日") + 1
                    )
                  }}</span
                >
                <span v-else>本场拍卖不支持看样、勘验</span>
              </div>
            </div>
            <img
              src="@/assets/images/bidHall/jianTou.png"
              style="max-width: 5%; height: 120px"
              alt=""
            />
            <div class="centerItem">
              <div class="item_tit">竞价起止时间:</div>

              <div class="item_label">拍卖时间：</div>
              <div class="item_value">
                {{ releasedData.auctionStartTime }} --
                {{ releasedData.auctionEndTime }}
              </div>
              <div class="item_label">延长情况：</div>
              <div class="item_value">
                若拍卖结束前{{
                  releasedData.delayLength
                }}分钟内有用户出价，整场即延时{{
                  releasedData.delayLength
                }}分钟结束，以此类推直至无人出价。
              </div>
            </div>
            <img
              src="@/assets/images/bidHall/jianTou.png"
              style="max-width: 5%; height: 120px"
              alt=""
            />
            <div class="centerItem">
              <div class="item_tit">买方提货:</div>

              <div class="item_label">提货时间：</div>
              <div class="item_value">以卖方通知为准</div>
              <div class="item_label">提货地址：</div>
              <div class="item_value">
                {{ releasedData.bidCountry }}-{{
                  releasedData.bidAddress
                }}，具体以卖方通知为准
              </div>
            </div>
          </div>

          <el-divider content-position="center" border-style="dashed"
            >竞买须知</el-divider
          >
          <div class="module" v-if="releasedData.previewDisplay != '0'">
            <div class="moduleHader">关于看样/勘验</div>
            <div class="moduleContent">
              <p v-if="releasedData.isPreview == 'Y'">
                本次拍卖提供看样/勘验服务，拍卖标的以实际现场看样勘验情况为准。
              </p>
              <p v-else>
                本次拍卖不提供看样/勘验服务，竟价标的以实际交接物资为准，平台对数量、品质不承担任何责任。
              </p>
              <div v-html="releasedData.previewInfo"></div>
            </div>
            <div class="moduleFoot">
              <div class="footLabel" style="width: 300px">
                看样/勘验预约咨询:
              </div>

              <div v-if="releasedData.isPreview == 'Y'" class="footValue">
                <span
                  style="margin-right: 40px"
                  v-for="(item, index) in releasedData.contactVoList"
                  :key="index"
                  >{{ item.previewContact }} :
                  {{ item.previewContactNumber }}</span
                >
              </div>
              <div v-else class="footNoValue">本次拍卖不提供看样/勘验服务</div>
            </div>
          </div>
          <div
            class="module"
            v-if="releasedData.qualityAgreementDisplay != '0'"
          >
            <div class="moduleHader">货品质量约定</div>
            <div class="moduleContent">
              <p v-if="releasedData.qualityAgreementFiles.length">
                本次拍卖标的由拍卖方提供介绍材料，提供资料包括：
                <span v-for="i in releasedData.zhiLiangList" :key="i.name"
                  >{{ i.name }} 、
                </span>
                均可通过平台下载资料。
              </p>
              <p v-else>本次拍卖标的由拍卖方提供介绍材料，通过线下沟通获取。</p>
              <div v-html="releasedData.qualityAgreementInfo"></div>
            </div>
            <div
              v-if="releasedData.qualityAgreementFiles.length"
              class="moduleFoot"
            >
              <div class="footLabel">质量说明资料:</div>
              <div class="footValue" v-if="userInfo.userName">
                <div
                  class="fileList"
                  v-for="i in releasedData.zhiLiangList"
                  :key="i.name"
                >
                  <div class="fileName">{{ i.name }}</div>
                  <div class="fileBtn" @click="handleDownload(i.url, i.name)">
                    下载
                  </div>
                </div>
              </div>
              <div v-else class="footNoValue">登录后可下载</div>
            </div>
          </div>
          <div
            class="module"
            v-if="releasedData.deliveryAgreementDisplay != '0'"
          >
            <div class="moduleHader">货品去向约定</div>
            <div class="moduleContent">
              <div v-html="releasedData.deliveryAgreementInfo"></div>
            </div>
          </div>
          <div class="module" v-if="releasedData.inventoryListDisplay != '0'">
            <div class="moduleHader">标的物资清单</div>
            <div class="moduleContent">
              <div
                v-if="
                  releasedData.inventoryListFiles.length ||
                  releasedData.inventoryListDisplay == '2'
                "
                v-html="releasedData.inventoryListInfo"
              ></div>
              <p v-else>
                本次拍卖不提供标的的标准物资清单，本次竞价的物资以现状展示、以实际移交为准，委托人与平台方无法按照清单状态移交。
              </p>
            </div>
            <div
              v-if="releasedData.inventoryListFiles.length"
              class="moduleFoot"
            >
              <div class="footLabel">物资清单资料:</div>
              <div class="footValue" v-if="userInfo.userName">
                <div
                  class="fileList"
                  v-for="i in releasedData.qingDanList"
                  :key="i.name"
                >
                  <div class="fileName">{{ i.name }}</div>
                  <div class="fileBtn" @click="handleDownload(i.url, i.name)">
                    下载
                  </div>
                </div>
              </div>
              <div v-else class="footNoValue">登录后可下载</div>
            </div>
          </div>
          <div class="module" v-if="releasedData.bidRequirementDisplay != '0'">
            <div class="moduleHader">参与竞价要求说明</div>
            <div class="moduleContent">
              <p>
                参加本次竞价的竞买方必须已在本站完成账户注册、企业实名认证。如有帐户注册、实名认证、竞价报名等问题请及时与客服沟通。提交的资质以及报名材料不全，报名将无法顺利完成或无法通过。
              </p>
              <p>参与竞价的竞买方与委托方、平台方无不良合作记录。</p>
              <p>
                参与竞价的竞买方须具备资质<span
                  v-if="releasedData.isQualify == 'Y'"
                  >： “废危品经营许可证”</span
                >且资质须符合回收金属、电池的类型。
              </p>
              <p>
                在竞价结束前<span>{{ releasedData.delayLength }}分钟</span
                >内，若有客户出价，则本场拍卖结束时间从出价时刻开始计算<span
                  >{{ releasedData.delayLength }}分钟</span
                >后结束，以此类推，直至再无客户出价，方则拍卖结束。
              </p>
              <p>
                合同签署采用线上签署的方式，平台接入E签宝线上签章功能。最终中标企业须通过平台完成向平台的签章授权，并以线上确认方式完成签署。合同起止时间双方商议后，以实际合同中协商的时间执行，交割时间同样在合同中进行明确。
              </p>
            </div>
          </div>
          <div class="module" v-if="releasedData.bidDepositDisplay != '0'">
            <div class="moduleHader">竞价保证金</div>
            <div class="moduleContent">
              <p>
                客户通过平台中的企业账户进行充值，在报名时缴纳
                <span>{{ releasedData.deposit }}元</span>
                竞价保证金可参与此次竞价，违约将扣除本次竞价保证金；
                充值收款公司为北京理工新源信息科技有限公司，充值付款账户户名必须与平台注册的企业全称保持一致。转账支付时请备注【拍卖平台充值】。
              </p>
              <p>
                竞价结束后，未成交竞买人的保证金在竞价结果公示后退回至企业账户，申请提现则款项在15个工作日内返回绑定的企业账户中。已成交竞买人保证金在支付交易服务费、完成合同签署、合同履行完成后，退款到企业账户中自行提现。
              </p>
            </div>
          </div>
          <div
            class="module"
            v-if="releasedData.qualificationAssessmentDisplay != '0'"
          >
            <div class="moduleHader">成交资格认定</div>
            <div class="moduleContent">
              <div v-html="releasedData.qualificationAssessmentInfo"></div>
            </div>
          </div>
          <div
            class="module"
            v-if="releasedData.handoverRequirementsDisplay != '0'"
          >
            <div class="moduleHader">交接要求</div>
            <div class="moduleContent">
              <div v-html="releasedData.handoverRequirementsInfo"></div>
            </div>
          </div>
          <div class="module" v-if="releasedData.bidWithdrawalDisplay != '0'">
            <div class="moduleHader">弃标责任</div>
            <div class="moduleContent">
              <div v-html="releasedData.bidWithdrawalInfo"></div>
            </div>
          </div>
          <div class="module" v-if="releasedData.otherNotesDisplay != '0'">
            <div class="moduleHader">其他说明</div>
            <div class="moduleContent">
              <div v-html="releasedData.otherNotesInfo"></div>
            </div>
            <div v-if="releasedData.otherNotesFiles.length" class="moduleFoot">
              <div class="footLabel">其他说明资料:</div>
              <div class="footValue" v-if="userInfo.userName">
                <div
                  class="fileList"
                  v-for="i in releasedData.qiTaList"
                  :key="i.name"
                >
                  <div class="fileName">{{ i.name }}</div>
                  <div class="fileBtn" @click="handleDownload(i.url, i.name)">
                    下载
                  </div>
                </div>
              </div>
              <div v-else class="footNoValue">登录后可下载</div>
            </div>
          </div>

          <div class="foot">
            <p>{{ releasedData.applyForBusiness }}</p>
            <p>
              {{
                releasedData.releaseTime
                  ? releasedData.releaseTime.split(" ")[0]
                  : "---"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { viewBidAnnouncement } from "@/api/bidProject";
import { viewBidAnnouncementById } from "@/api/workSpace";
import { download } from "@/utils/publicFunction";
export default {
  data() {
    return {
      releasedData: {},
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted() {
    if (this.$route.query.auctionCode) {
      this.getGongGao();
    } else if (this.$route.query.id) {
      this.viewBidAnnouncementById();
    }
  },
  methods: {
    // 通过auctionCode查公告详情
    getGongGao() {
      viewBidAnnouncement(this.$route.query).then(({ code, data }) => {
        if (code == 200) {
          this.releasedData = data.applyAndConfigDTO;
          if (this.releasedData.contactVoList) {
            this.releasedData.contactVoList = JSON.parse(
              this.releasedData.contactVoList
            );
          }
          if (this.releasedData.qualityAgreementFiles.length) {
            this.releasedData.zhiLiangList =
              this.releasedData.qualityAgreementFiles.map((i) => ({
                name: i.split("zhiLiang_")[1],
                url: i,
              }));
          }
          if (this.releasedData.inventoryListFiles.length) {
            this.releasedData.qingDanList =
              this.releasedData.inventoryListFiles.map((i) => ({
                name: i.split("qingDan_")[1],
                url: i,
              }));
          }
          if (this.releasedData.otherNotesFiles.length) {
            this.releasedData.qiTaList = this.releasedData.otherNotesFiles.map(
              (i) => ({
                name: i.split("qiTa_")[1],
                url: i,
              })
            );
          }
        }
      });
    },

    // 通过id查公告详情 拍卖申请预览公告详情使用
    viewBidAnnouncementById() {
      viewBidAnnouncementById(this.$route.query).then(({ code, data }) => {
        if (code == 200) {
          this.releasedData = data.applyAndConfigDTO;
          if (this.releasedData.contactVoList) {
            this.releasedData.contactVoList = JSON.parse(
              this.releasedData.contactVoList
            );
          }
          if (this.releasedData.qualityAgreementFiles.length) {
            this.releasedData.zhiLiangList =
              this.releasedData.qualityAgreementFiles.map((i) => ({
                name: i.split("zhiLiang_")[1],
                url: i,
              }));
          }
          if (this.releasedData.inventoryListFiles.length) {
            this.releasedData.qingDanList =
              this.releasedData.inventoryListFiles.map((i) => ({
                name: i.split("qingDan_")[1],
                url: i,
              }));
          }
          if (this.releasedData.otherNotesFiles.length) {
            this.releasedData.qiTaList = this.releasedData.otherNotesFiles.map(
              (i) => ({
                name: i.split("qiTa_")[1],
                url: i,
              })
            );
          }
        }
      });
    },
    // 下载
    handleDownload(url, fileName) {
      download("/file/downloadFile", { objectName: url }, `${fileName}`);
    },
  },
};
</script>

<style>
.el-divider__text {
  font-size: 32px;
  font-weight: 600;
}
</style>
<style lang="less" scoped>
.container {
  width: 100%;
  min-height: 73vh;
  .noticeBox {
    width: 1274px;
    height: auto;
    margin: 0 auto;

    .title {
      height: 60px;
      font-weight: 500;
      font-size: 28px;
      color: #000000d9;
      margin-top: 80px;
      border-bottom: 1px solid #e0e0e0;
    }
    .content {
      width: 100%;
      height: auto;
      padding-top: 10px;
      box-sizing: border-box;
      .time {
        display: flex;
        justify-content: end;
        font-size: 18px;
        font-weight: 400;
        color: #00000099;
        line-height: 40px;
        .line {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      .contentDetail {
        font-size: 20px;
        padding: 30px 80px;
        box-sizing: border-box;
        line-height: 1.8em;
        text-align: justify;
        color: #333;
        .tit {
          text-indent: 2em;
          padding-bottom: 20px;
          span {
            font-weight: 600;
          }
        }

        .flex3Box {
          width: 100%;
          display: flex;
          // justify-content: space-between;
          align-items: center;
          margin-bottom: 90px;
          .centerItem {
            width: 30%;
            border-radius: 8px;
            height: 290px;
            background-color: #f1f1f1;
            padding: 20px;
            box-sizing: border-box;
            .item_tit {
              font-size: 24px;
              color: #333;
              text-align: center;
              font-weight: 600;
              margin-bottom: 10px;
            }
            .item_label {
              font-size: 18px;
              color: #999;
            }
            .item_value {
              font-size: 16px;
              color: #333;
              font-weight: 600;
              margin-bottom: 10px;
              line-height: 1.6em;
            }
          }
        }
        .module {
          margin-top: 40px;
          .moduleHader {
            width: 100%;
            font-size: 24px;
            font-weight: 600;
            border-left: 8px solid rgb(42, 211, 150);
            padding-left: 10px;
            line-height: 1.2em;
            margin-bottom: 20px;
          }
          .moduleContent {
            text-indent: 2em;
            margin-bottom: 10px;
            span {
              font-weight: 600;
            }
            /deep/ table,
            /deep/ th,
            /deep/ td {
              border: 1px solid #ccc;
              text-align: center;
              padding: 5px 0;
            }
            /deep/ .firstRow {
              background: #f5f5f5;
              font-weight: 600;
            }
          }
          .moduleFoot {
            width: 100%;
            border-radius: 8px;
            height: 60px;
            background-color: #f1f1f1;
            padding-left: 60px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            .footLabel {
              min-width: 180px;
              font-size: 22px;
              font-weight: 600;
            }
            .footValue {
              font-size: 20px;
              font-weight: 600;
              display: flex;
              flex-wrap: wrap;
              .fileList {
                margin-left: 20px;
                font-weight: 500;
                display: flex;
                font-size: 14px;
                .fileName {
                  margin-right: 2px;
                }
                .fileBtn {
                  cursor: pointer;
                  color: #02b548;
                  font-weight: 600;
                }
              }
            }
            .footNoValue {
              margin-left: 140px;
              font-size: 20px;
              font-weight: 600;
              color: #aaa;
            }
          }
        }

        .foot {
          text-align: end;
          padding-top: 80px;
        }
        // p {
        //   text-indent: 2em;
        //   line-height: 2.2em;
        //   padding-bottom: 10px;
        // }
      }
    }
  }
}
</style>
<style lang="less">
.bdConte {
  background-color: #f1f1f1;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  p {
    width: 100% !important;
  }
  table,
  th,
  td {
    border: 1px solid #ccc !important;
    text-align: center;
    padding: 5px 0;
  }
  .firstRow {
    background-color: #fff;
    font-weight: 600;
  }
}
</style>
